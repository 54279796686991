import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { AddToCart } from "../cart/miniCart"
import ProductsNew from "../goods/ProductsNew"
import PageContext from "../../context/PageContext"
import { GOOD_DETAIL } from "../../query/goods"
import { BsFolder2 } from "react-icons/bs"
import { useQuery } from "@apollo/client"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { SelectedProducts } from "./SelectedProducts"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "../../client/apollo"
import { Loading } from "../layout/Loading"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export const ProductDetail = ({ pageContext, location }) => {
  const fileData = useStaticQuery(graphql`
    query FileQueryDetail {
      allProduct {
        nodes {
          id
          productId
          name
          localFile {
            childImageSharp {
              gatsbyImageData(height: 220)
            }
          }
        }
      }
    }
  `)

  const [context, dispatch] = useContext(PageContext)
  const searchString = context?.searchString

  const [selected, setSelected] = useState(0)
  const [item, setItem] = useState(pageContext)
  const scroller = useRef()
  const visibleArea = useRef()

  let sections = item?.description?.split("<section>")
  let description = []
  let fraze = ""
  if (sections && sections.length > 0) {
    let sectionParts = sections[0].split("<")
    sectionParts = sectionParts[0].split(" ")
    sectionParts.forEach((part, partIdx) => {
      fraze = fraze + part
      if (partIdx === 1) {
        return ""
      } else {
        fraze = fraze + " "
      }
    })
    sections.forEach(section => {
      const subSections = section.split("<subsection>")
      if (subSections && subSections.length > 0) {
        const desc = {
          title: subSections[0],
          content: subSections[1],
        }
        description.push(desc)
      }
    })
  }

  const { loading, data } = useQuery(GOOD_DETAIL, {
    variables: {
      id: "/api/goods/" + item._id,
    },
  })

  useEffect(() => {
    if (data) {
      setItem({
        ...item,
        quantity: data.good.quantity,
        priceVat: data.good.priceVat,
        visible: data.good.visible,
      })
    }
    //eslint-disable-next-line
  }, [data])

  let sale = false
  if (item.supRrp > 0 && item.priceVat < item.supRrp) {
    sale = Math.floor(100 - (item.priceVat * 100) / item.supRrp)
  }

  const product = {
    name: item.name,
    price: parseInt(item.priceVat).toLocaleString() + " Kč",
    rating: 4,
    images: item.files.map(file => ({
      name: item.name,
      src: file.filename,
      alt: item.name,
    })),
    description: item.description,
    table: [
      {
        name: "Dostupnost",
        value:
          item.quantity > 0 && item.visible ? (
            <span className="font-bold text-green-600">skladem</span>
          ) : (
            item.note
          ),
      },
      {
        name: "Výrobce",
        value: (
          <div>
            <Link
              to={
                "/produkty/?v=" +
                encodeURIComponent(item.producer).replace(/%20/g, "+")
              }
              className="text-amber-600 underline hover:no-underline"
            >
              {item.producer}
            </Link>
          </div>
        ),
      },
    ],
    details: [
      {
        name: "Výrobce",
        value: (
          <div>
            <Link
              to={
                "/produkty/?v=" +
                encodeURIComponent(item.producer).replace(/%20/g, "+")
              }
              className="text-amber-600 underline hover:no-underline"
            >
              {item.producer}
            </Link>{" "}
            / {item.manItem}
          </div>
        ),
      },
      { name: "Kód zboží", value: item.code },
      { name: "EAN", value: item.ean },
    ],
  }
  item.quantity > 0 &&
    product.table.push({
      name: "Expedujeme",
      value: "dnes nebo následující pracovní den",
    })

  let catData = item?.categories[0]

  useEffect(() => {
    let breadCrumb = context.breadCrumbPath
    if (breadCrumb && breadCrumb.length === 0) {
      //neni nastavena, nastavime prvni cat
      if (item?.categories[0]) {
        let allCats = data?.allCats
        let catId = catData?.parentId
        let mainId = 1

        if (allCats && catId) {
          let finalPath = []
          finalPath[catData.lft] = {
            ...catData,
            menuName: catData.name,
            url: "/produkty/" + catData.url + "/",
            alias: catData.url,
          }

          function findParent(parent) {
            allCats.forEach(menuItem => {
              if (menuItem._id === parent && menuItem._id !== mainId) {
                finalPath[menuItem.lft] = {
                  ...menuItem,
                  url: "/produkty/" + menuItem.url + "/",
                  alias: menuItem.url,
                }
                if (menuItem.parentId !== mainId) {
                  findParent(menuItem.parentId)
                }
              }
            })
          }

          findParent(catData.parentId)

          dispatch({
            type: "SET_BREADCRUMB",
            payload: {
              breadCrumbPath: finalPath,
            },
          })
        }
      }
    }

    const width = visibleArea?.current?.clientWidth
    function updateScrollPosition() {
      const fromLeft = scroller.current.scrollLeft

      let visibleImg = 0
      if (fromLeft > 0) {
        visibleImg = Math.round(fromLeft / width)
      }
      setSelected(visibleImg)
    }

    if (scroller && scroller.current) {
      scroller.current.addEventListener("scroll", updateScrollPosition, false)
      return function cleanup() {
        //eslint-disable-next-line
        scroller?.current?.removeEventListener(
          "scroll",
          updateScrollPosition,
          false
        )
      }
    }
    //eslint-disable-next-line
  }, [item])

  const itemRefs = useRef(new Map())

  const executeScroll = imageIdx => {
    //setSelected(imageIdx)
    itemRefs.current
      .get(imageIdx)
      .scrollIntoView({ behavior: "auto", block: "nearest", inline: "start" })
  }

  const title =
    item.name +
    " " +
    item.rod +
    " " +
    (item.quantity > 0 ? "skladem" : "") +
    (sale ? "/ " + sale + "% sleva, nyní za " + item.priceVat + " Kč" : "")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | FÖDA švédské potraviny</title>
      </Helmet>
      {searchString && (
        <>
          <ProductsNew location={location} />
        </>
      )}

      {!searchString && (
        <>
          <div className="bg-white">
            <div className="max-w-2xl mx-auto py-4  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                <div className="flex flex-col-reverse" ref={visibleArea}>
                  {item.priceVat > 0 && (
                    <div className="  mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                      <div className="grid grid-cols-4 lg:grid-cols-5 gap-2 relative">
                        {product.images.map((image, imageIdx) => (
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => executeScroll(imageIdx)}
                            onKeyDown={() => executeScroll(imageIdx)}
                            key={imageIdx}
                            className="relative h-24 bg-white rounded-md flex items-center   justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer   hover:bg-gray-50 focus:outline-none"
                          >
                            <span className="sr-only">{image?.name}</span>
                            <span className="absolute inset-0 rounded-md overflow-hidden p-5">
                              <img
                                src={image?.src}
                                alt=""
                                className="object-contain w-full max-h-full  "
                              />
                            </span>
                            <span
                              className={classNames(
                                selected === imageIdx
                                  ? "ring-amber-500"
                                  : "ring-transparent",
                                "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none border transition-all ease-in-out duration-300 "
                              )}
                              aria-hidden="true"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div
                    className="w-full flex overflow-x-auto snap-x snap-mandatory no-scrollbar rounded-md shadow-md min-h-96 max-h-[600px] items-center border"
                    ref={scroller}
                  >
                    {product.images.map((image, imageIdx) => (
                      <div
                        key={imageIdx}
                        ref={e => itemRefs.current.set(imageIdx, e)}
                        className={
                          "w-full flex-shrink-0  snap-center relative p-8  rounded-md overflow-hidden " +
                          (item.priceVat === 0 && "opacity-70")
                        }
                      >
                        <img
                          src={image?.src}
                          aria-hidden
                          alt={image?.alt}
                          className="object-contain h-full w-full max-h-[600px]  "
                        />
                        {item.priceVat === 0 && (
                          <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full text-center flex items-center justify-center">
                            <span className="p-5 bg-black bg-opacity-50 text-white font-bold w-full">
                              Prodej ukončen
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Product info */}
                <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
                  <div className="flex justify-between space-x-2">
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-600">
                      {product.name}{" "}
                    </h1>
                  </div>
                  {item.priceVat > 0 && (
                    <div className="mt-6 grid space-y-8 sm:space-y-0 sm:grid-cols-2 p-3 bg-gray-100">
                      <div>
                        <div className="grid sm:flex sm:space-x-4 items-center text-2xl">
                          <p className=" text-sky-700 font-bold">
                            {product.price}
                          </p>
                          {sale && (
                            <p className="line-through text-gray-500 text-lg">
                              {item.supRrp.toLocaleString()} Kč
                            </p>
                          )}
                        </div>
                        <div className="mt-1">
                          {item.quantity > 0 && item.visible ? (
                            <div className=" text-green-600 font-bold">
                              {item.quantity === 1 && "Poslední kus"} skladem
                            </div>
                          ) : (
                            <div className="text-gray-600"></div>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center  text-center">
                        {item.quantity > 0 && item.visible ? (
                          <AddToCart item={item} />
                        ) : !loading ? (
                          <>
                            <div className="flex space-x-2 w-full justify-center items-center h-12 px-4 py-2 text-gray-100 bg-gradient-to-b from-gray-600 to-gray-700 rounded-md border border-green-800   shadow-md opacity-50">
                              <span className="truncate">
                                Dočasně nedostupné
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="w-full">
                            <svg
                              className="animate-spin w-6 h-6 inline  "
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-6">
                    <div className="grid  ">
                      <table className="min-w-full divide-y divide-gray-200  border col-span-4">
                        <tbody>
                          {item.priceVat > 0 && (
                            <tr className="bg-gray-50">
                              <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                Cena s DPH
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                                {product.price}
                              </td>
                            </tr>
                          )}
                          {product.table.map((row, rowIdx) => (
                            <tr
                              key={rowIdx}
                              className={
                                rowIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }
                            >
                              <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                {row.name}
                              </td>
                              <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                                {row.value}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <div className=" text-gray-700 space-y-6  mt-10">
                      {description.length > 0 &&
                        description.map((section, sectionIdx) => (
                          <div key={sectionIdx}>
                            <div className="text-lg font-bold">
                              <Link
                                to={
                                  "/vyhledavani/?s=" +
                                  encodeURIComponent(section.title).replace(
                                    /%20/g,
                                    "+"
                                  )
                                }
                                className="mb-1 text-amber-600 underline hover:no-underline"
                              >
                                {section.title}
                              </Link>
                            </div>
                            {section.content !== "<p>~</p>" && (
                              <div
                                className="prose prose-sm max-w-max"
                                dangerouslySetInnerHTML={{
                                  __html: section.content,
                                }}
                              />
                            )}
                          </div>
                        ))}
                      <div
                        className="prose prose-sm max-w-max"
                        dangerouslySetInnerHTML={{
                          __html: item.descriptionExt,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 bg-gray-100 p-4 mt-10">
                <div>
                  <div className=" p-4">
                    <div className="my-2 text-lg font-bold text-gray-500">
                      Produkt{" "}
                      <Link
                        to={"/produkty/detail/" + item.alias + "/"}
                        className=" text-amber-600 underline hover:text-amber-800 hover:no-underline"
                      >
                        {item.name}
                      </Link>{" "}
                      je s dalšími potravinami v kategoriích:
                    </div>
                    <div className="text-sm mt-3">
                      {item.categories.map((cat, catIdx) => (
                        <div
                          key={catIdx}
                          className="  lg:flex space-x-2 items-center mb-1"
                        >
                          <span className="inline-flex space-x-2 items-center">
                            <div className="flex items-center space-x-2 text-gray-500  ">
                              <span className=" ">Švédské potraviny</span>
                            </div>
                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-300" />
                          </span>
                          <span
                            className="inline-flex space-x-2 items-center"
                            key={catIdx}
                          >
                            {catIdx > 0 && (
                              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-300" />
                            )}
                            <Link
                              to={"/produkty/" + cat.url + "/"}
                              className="flex items-center space-x-2 text-amber-600 underline hover:text-amber-800 hover:no-underline"
                            >
                              <BsFolder2 className="w-4 h-4 opacity-70 " />
                              <span className="truncate">{cat.name}</span>
                            </Link>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {item.priceVat > 0 && (
                  <div className="-order-1 lg:order-2">
                    <div>
                      <div className="p-4">
                        <div className="my-2 text-lg font-bold text-gray-500">
                          Podrobnosti
                        </div>
                        <table className="min-w-full divide-y divide-gray-200 my-3 border">
                          <tbody>
                            {product.details.map((row, rowIdx) => (
                              <tr
                                key={rowIdx}
                                className={
                                  rowIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                                }
                              >
                                <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {row.name}
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                                  {row.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="mt-6 grid space-y-8 sm:space-y-0 sm:grid-cols-2 p-3 bg-gray-100">
                      <div>
                        <div className="grid sm:flex sm:space-x-4 items-center text-2xl">
                          <p className=" text-sky-700 font-bold">
                            {product.price}
                          </p>
                          {sale && (
                            <p className="line-through text-gray-500 text-lg">
                              {item.supRrp.toLocaleString()} Kč
                            </p>
                          )}
                        </div>
                        <div className="mt-1">
                          {item.quantity > 0 && item.visible ? (
                            <div className=" text-green-600 font-bold">
                              skladem
                            </div>
                          ) : (
                            <div className="text-gray-600"></div>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center  text-center">
                        {item.quantity > 0 && item.visible ? (
                          <AddToCart item={item} />
                        ) : !loading ? (
                          <>
                            <div className="flex space-x-2 w-full justify-center items-center h-12 px-4 py-2 text-gray-100 bg-gradient-to-b from-gray-600 to-gray-700 rounded-md border border-green-800   shadow-md opacity-50">
                              <span className="truncate">
                                Dočasně nedostupné
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="w-full">
                            <svg
                              className="animate-spin w-6 h-6 inline  "
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div></div>
              <div className="mt-16">
                <div className="text-xl px-8">
                  Další produkty z kategorie <span>{catData?.name}</span>
                </div>

                <ApolloProvider client={apolloClient}>
                  <SelectedProducts
                    type={"cat"}
                    catData={catData}
                    fileData={fileData}
                  />
                </ApolloProvider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
