import React from "react"
import { ProductDetail } from "../components/goods/ProductDetail";
import { ApolloProvider } from "@apollo/client";
import { apolloClient  } from "../client/apollo";  


const Product = ({ data, classes , pageContext, location, ...props }) => {
  
   
  return (

    <>
      <ApolloProvider client={apolloClient}>  
          <ProductDetail data={data} classes={classes} pageContext={pageContext} location={location} /> 
      </ApolloProvider>
    </>
  )
}

export default Product
 